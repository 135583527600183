import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import Sidebar from "../Sidebar/Sidebar";
import profileActions from '../../store/profileStore/profileActions';
import ScrollToTop from "../ScrollToTop";
import { getAllowedRoutes } from "../../tools/utils";
import PrivateRoutesConfig from "../../config/PrivateRoutesConfig";
import { useCurrentBranch } from "../../hooks/useCurrentBranch";
import styles from './Layout.module.css';
import directoryActions from "../../store/directoryStore/directoryActions";

export default function Layout() {
    const dispatch = useDispatch();
    const history = useHistory()
    const { currentBranch } = useCurrentBranch();

    let allowedRoutes = [];
    if (currentBranch) {
        allowedRoutes = getAllowedRoutes(PrivateRoutesConfig, [currentBranch.role]);
    }

    useEffect(() => {
        dispatch(profileActions.getProfile());
        dispatch(directoryActions.getCities());
    }, []);

    useEffect(() => {
        if (currentBranch && allowedRoutes.length && !allowedRoutes.map(a => a.path).includes('/')) {
            history.push(allowedRoutes[0].path);
        }
    }, [currentBranch])

    return (
        <div className={styles.container}>
            <Sidebar/>
            <ScrollToTop>
                <div className={styles.content}>
                    <Switch>
                        {allowedRoutes.map((route) => {
                            const {
                                path,
                                component: Component,
                                children,
                                title,
                                permission,
                                exact,
                                ...rest
                            } = route;
                            return (
                              <Route
                                {...rest}
                                key={path}
                                path={path}
                                exact={exact}
                                render={() => <Component />}
                              />
                            )
                        })}
                        <Redirect to="/404" />
                    </Switch>
                </div>
            </ScrollToTop>
        </div>
    )
}


