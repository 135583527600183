import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { PENDING } from '../../../store/globalStatuses/globalStatuses';
import Loader from '../../Loader';
import transactionsActions from '../../../store/transactionsStore/transactionsActions';
import { mask } from 'helpers';
import instance from "../../../api";

import styles from './findCustomer.module.scss';
import { PhoneInputField } from "../../PhoneInput";
import ReactSelect, { components } from "react-select";

export default function FindCustomer({ setStep, setNotRegistered, setPhone, type, setType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {cStatus} = useSelector(state => state.clientInfoReducer);
  const {info} = useSelector(state => state.branchReducer);
  const refPhone = useRef()

  const [error, setError] = useState(false);
  const [value, setValue] = useState('');

  const [inputValue, setInputValue] = useState(""); // Значение поиска (4 цифры)
  const [options, setOptions] = useState([]); // Список опций
  const [hasMore, setHasMore] = useState(true); // Есть ли еще данные
  const [loading, setLoading] = useState(false); // Статус загрузки

  const loadOptions = async (inputValue, page = 0) => {
    if (loading || !inputValue || inputValue.length !== 4) return; // Если идет загрузка или длина ввода не равна 4

    setLoading(true);

    try {
      const response = await instance().get("/user/phone/search", {
        params: {
          query: inputValue,
          pageNumber: page,
          pageSize: 20,
        },
      });

      const newOptions = response.data.data.map((option) => ({label: option.fullName?.replace(/undefined/g, ""), value: option.phone})); // Предполагаем, что сервер возвращает данные в поле items
      setOptions((prevOptions) => [...prevOptions, ...newOptions]);

      setHasMore(response.data.hasMore); // Если сервер сообщает, что есть еще данные
    } catch (error) {
      console.error("Error loading data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    // setOptions([]); // Очищаем старые результаты при новом вводе
    loadOptions(newValue);
  };

  // Обработчик скроллинга
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && hasMore && !loading) {
      const nextPage = Math.ceil(options.length / 20) + 1;
      loadOptions(inputValue, nextPage);
    }
  };

  const findCustomer = (e) => {
    e.preventDefault();

    let v = value;
    if (type === 'phone') {
      v = v.replace(/\D/g, '');
    }

    new Promise((resolve, reject) => {
      dispatch(transactionsActions.getUserInfo(info.id, info.group_id, type, v, resolve, reject))
    })
      .then(() => {
        setStep(2);
      })
      .catch((e) => {
        if (type === 'code') return setError(true);
        if (e.code === 100) {
          setNotRegistered(true);
          setPhone(v);
        }

        setStep(2);
      })
  }

  const findCustomerByPhone = (phone) => {
    new Promise((resolve, reject) => {
      dispatch(transactionsActions.getUserInfo(info.id, info.group_id, 'phone', phone, resolve, reject))
    })
      .then(() => {
        setStep(2);
      })
      .catch((e) => {
        if (e.code === 100) {
          setNotRegistered(true);
          setPhone(phone);
        }

        setStep(2);
      })
  }

  const onPhoneChange = (value) => {
    setError(false);
    setValue(value);
  }

  const onTypeChange = () => {
    setValue('');

    switch (type) {
      case 'phone':
        setType('code');
        break;
      case 'code':
        setType('phone');
        break;
    }
  }

  const onCodeChange = (e) => {
    if (error) setError(false);
    setValue(mask(e.target.value, '### ####', 7));
  }

  useEffect(() => {
    if (refPhone.current) {
      const clickEvent = new MouseEvent("focus", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      const input = refPhone.current.querySelector('input');
      input.focus()
      input.dispatchEvent(clickEvent)
    }
  }, [refPhone]);

  let disabled = false;

  if (type === 'code' && value.length < 7) disabled = true
  if (type === 'phone' && value.length < 7) disabled = true

  const CustomMenuItem = (props) => {
    return (
      <components.Option {...props}>
        <div className={styles.option}>
          <span>{props.value}</span> {props.label ? <span>{props.label}</span> : null}
        </div>
      </components.Option>
    );
  };

  return (
    <form onSubmit={cStatus !== PENDING ? findCustomer : null} className={styles.container}>
      <p>{t('NSPayModal.enter')}</p>
      <div className={styles.secondInner}>
        {type === 'phone' && <PhoneInputField
          value={value}
          disabled={cStatus === PENDING}
          ref={refPhone}
          onChange={onPhoneChange}
          label={t('NSAuth.phone')}
        />}
      </div>
      <div className={styles.inner}>
        {type === 'code' && <input
          autoFocus
          maxLength={7}
          onChange={onCodeChange}
          value={value}
          type="text"
          disabled={cStatus === PENDING}
          placeholder={t('NSPayModal.enter6')}
        />}
        <button type="button" onClick={onTypeChange} className={styles.switcher}>
          {type === 'phone' && t('NSPayModal.enter6')}
          {type === 'code' && t('NSPayModal.enterPhone')}
        </button>
        {error && <span className={styles.span}>{t('NSPayModal.notValid6')}</span>}
      </div>

      <div className={styles.secondInner} style={{ marginTop: '1rem' }}>
        <ReactSelect
          value={null}
          inputValue={inputValue}
          placeholder="Введите послдение 4 цифры телефона"
          onInputChange={handleInputChange}
          onChange={(v) => findCustomerByPhone(v.value?.replace(/\+/g, ""))}
          options={options}
          isLoading={loading}
          onMenuClose={() => {}}
          onMenuOpen={() => {}}
          components={{ Option: CustomMenuItem}}
          maxMenuHeight={160}
        />
      </div>
      <button
        type="submit"
        className={styles.btn}
        disabled={cStatus === PENDING || disabled}
        style={cStatus === PENDING ? {background: 'gray'} : {}}>
        {cStatus === PENDING ? <Loader/> : t('proceed')}
      </button>
    </form>
  )
}