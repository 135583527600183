import React from 'react'
import { useTranslation } from "react-i18next";
import {convertDataToTime, convertDateToShortString} from "../../../tools/convertDateToString";
import { format } from "date-fns"
import styles from './DataTableClientInfo.module.css'

export default function DataTableClientInfo(props) {
    const { t } = useTranslation();
    const {page, transactions, tStatus, changeTransactionPageAction, count} = props

    const pages = []

    for (let i = 1; i <= Math.ceil(count / 5); i++) {
        pages.push(i)
    }

    const onChangePageByNumbers = (page) => {
        changeTransactionPageAction(page)
    }


    const onChangePageForward = () => {
        if (page === pages.length) {
            changeTransactionPageAction(1)
        } else {
            changeTransactionPageAction(page + 1)
        }
    }

    const onChangePageToBack = () => {
        if (page === 1) {
            changeTransactionPageAction(pages.length)
        } else {
            changeTransactionPageAction(page - 1)
        }
    }

    const renderStatus = (order) => {
        if (order.status === 'DECLINED') {
            return <span className={styles.status} style={{background: '#FA6262', padding: "8px 19px"}}>{t('refused')}</span>
        }
        if (
          (order.initial_order_total_amount !== 0) && order.status === 'COMPLETED' || order.refunds?.length > 0
        ) {
            return <span className={styles.status} style={{background: order.refunds?.length ? 'rgb(112, 90, 251)' : '#24CC64', padding: "8px 22px"}}>{t("returned")}</span>
        }
        if ((order.initial_order_total_amount == undefined || order.initial_order_total_amount == 0) && order.status === 'COMPLETED') {
            return <span className={styles.status} style={{background: '#24CC64', padding: "8px 22px"}}>{t("received")}</span>
        }
        return <span className={styles.status} style={{background: '#6979F8', padding: "8px 12px"}}>{t('NSTransactions.processing')}</span>
    }

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{t('sum')}</th>
                    <th>{t('status')}</th>
                    <th>{t('startBalance')}</th>
                    <th>{t('NSCrossMarketing.sumBon')}</th>
                    <th>{t('NSCrossMarketing.sourceBonus')}</th>
                    <th>{t('typeOfPayment')}</th>
                    <th>{t('date')}</th>
                    <th>{t('nextAutoMailingDate')}</th>
                    <th>{t('time')}</th>
                    <th>{t('award')}</th>
                    <th>{t('comment')}</th>
                </tr>
                </thead>

                {/*3 types of status in .css*/}
                <tbody>
                {transactions && transactions.length ? transactions.map((transaction, index) => (
                  <tr key={index}>
                      <td>{page === 1 ? index + 1 : (page - 1) * 5 + index + 1}</td>
                      <td>{transaction.order_total_amount}</td>
                      <td>
                          {renderStatus(transaction)}
                      </td>
                      <td style={{textAlign: "center"}}>
                          {transaction?.sender?.current_private_balance ? transaction?.sender?.current_private_balance : "-"}
                      </td>
                      <td>{transaction.order_cashback_amount}</td>
                      <td>{transaction?.sale?.title || '-'}</td>
                      <td>{transaction.type}</td>
                      <td>{convertDateToShortString(transaction.created_at)}</td>
                      <td>
                          {
                              (transaction.auto_notification && transaction.auto_notification.next_date) ?
                                format(transaction.auto_notification.next_date, "DD.MM.YY", new Date()) :
                                null
                          }
                      </td>
                      <td>{convertDataToTime(transaction.created_at)}</td>
                      <td>
                          {
                            (transaction.award && transaction.award.give_award && t('received1')) ||
                            (transaction.award && transaction.award.add_stamp && t('given1')) ||
                            '-'
                          }
                      </td>
                      <td>
                          {transaction.comment || '-'}
                      </td>
                  </tr>
                )) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    </tr>
                )}

                </tbody>

            </table>
            {pages.length > 1 &&
            <div className={styles.pagination}>
                <i className="fa fa-chevron-left" aria-hidden="true" onClick={onChangePageToBack}/>
                <div className={styles.paginationNumbers}>
                    {
                        page > 5 &&
                        <>
                            <p onClick={() => onChangePageByNumbers(1)}>1</p>
                            <p>...</p>
                        </>
                    }
                    {pages.map((number, index) => (page + 6 > number && page - 5 < number) && (
                            <p
                                key={index}
                                style={page === number ? {background: "#E7E7E6"} : {}}
                                onClick={() => onChangePageByNumbers(number)}
                            >
                                {number}
                            </p>
                        )
                    )}
                    {page !== pages.length && pages.length - 1 && pages.length > 6 &&
                    <>
                        <p>...</p>
                        <p onClick={() => onChangePageByNumbers(pages.length)}>{pages.length}</p>
                    </>

                    }
                </div>
                <i className="fa fa-chevron-right" aria-hidden="true" onClick={onChangePageForward}/>
            </div>
            }
        </div>
    )
}