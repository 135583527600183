import React from 'react'
import {convertDateToShortString} from "../../../tools/convertDateToString";
import styles from './DataTableRFMClients.module.css'
import {useTranslation} from "react-i18next";

export default function DataTableRFMClients(props) {
    const { t } = useTranslation()
    const {customers, count, page, changePageAction, status, chooseClientAction} = props

    const pages = []

    for (let i = 1; i <= Math.ceil(count / 10); i++) {
        pages.push(i)
    }

    const onChangePageByNumbers = (page) => {
        changePageAction(page)
    }


    const onChangePageForward = () => {
        if (page === pages.length) {
            changePageAction(1)
        } else {
            changePageAction(page + 1)
        }
    }

    const onChangePageToBack = () => {
        if (page === 1) {
            changePageAction(pages.length)
        } else {
            changePageAction(page - 1)
        }
    }

    const onChooseClientAction = (id) => {
        chooseClientAction(id)
    }


    return (
        <div className={styles.container} style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{t('client')}</th>
                    <th>{t('NSStatistics.last')}</th>
                    <th>{t('NSStatistics.amount')}</th>
                    <th>{t('NSStatistics.total')}</th>
                    <th>R</th>
                    <th>F</th>
                    <th>M</th>
                </tr>
                </thead>
                <tbody>
                {customers && customers.length ? customers.map((customer, index) => (
                    <tr key={index} onClick={() => onChooseClientAction(customer.id)}>
                        <td>{index + 1}</td>
                        <td>{customer.first_name} {customer.last_name}</td>
                        <td>{convertDateToShortString(customer.last_order)}</td>
                        <td>{customer.transactions_count}</td>
                        <td>{customer.total_amount}</td>
                        <td>{customer.recency}</td>
                        <td>{customer.frequency}</td>
                        <td>{customer.monetary}</td>
                    </tr>
                )) : (
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                )}

                </tbody>


            </table>
            {pages.length > 1 &&
            <div className={styles.pagination}>
                <i className="fa fa-chevron-left" aria-hidden="true" onClick={onChangePageToBack}/>
                <div className={styles.paginationNumbers}>
                    {
                        page > 5 &&
                        <>
                            <p onClick={() => onChangePageByNumbers(1)}>1</p>
                            <p>...</p>
                        </>
                    }
                    {pages.map((number, index) => (page + 6 > number && page - 5 < number && (number < pages[pages.length - 1] || page == pages[pages.length - 1] || page == pages[pages.length - 2])) && (
                            <p
                                key={index}
                                style={page === number ? {background: "#E7E7E6"} : {}}
                                onClick={() => onChangePageByNumbers(number)}
                            >
                                {number}
                            </p>
                        )
                    )}
                    {page !== pages.length && pages.length - 1 && pages.length > 6 && page !== pages[pages.length - 2] &&
                    <>
                        <p>...</p>
                        <p onClick={() => onChangePageByNumbers(pages.length)}>{pages.length}</p>
                    </>

                    }
                </div>
                <i className="fa fa-chevron-right" aria-hidden="true" onClick={onChangePageForward}/>
            </div>
            }
        </div>

    )
}