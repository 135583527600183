import React, { useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone';
import styles from './customPhotoDropzone.module.scss';

const uploadSvg = (
    <svg width="48" height="43" viewBox="0 0 48 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.1686 11.8994C36.5723 3.89024 28.7857 -1.3082 20.7765 0.288074C14.9186 1.45562 10.3382 6.03315 9.16702 11.8904C3.48829 12.5448 -0.585143 17.6787 0.0689439 23.3578C0.671107 28.5843 5.09791 32.5276 10.3591 32.524H17.7524C18.5691 32.524 19.2311 31.862 19.2311 31.0453C19.2311 30.2286 18.5691 29.5666 17.7524 29.5666H10.3591C6.27555 29.5424 2.98529 26.2128 3.00909 22.1297C3.03325 18.0465 6.36281 14.7559 10.4463 14.7797C11.2 14.78 11.8331 14.2128 11.9161 13.4639C12.6668 6.97389 18.5366 2.32137 25.0266 3.07209C30.4829 3.70346 34.7871 8.0073 35.4181 13.4639C35.5421 14.2262 36.2027 14.7847 36.9751 14.7797C41.0586 14.7797 44.3687 18.0898 44.3687 22.1733C44.3687 26.2565 41.0586 29.5666 36.9751 29.5666H29.5818C28.7651 29.5666 28.1031 30.2286 28.1031 31.0453C28.1031 31.862 28.7651 32.524 29.5818 32.524H36.9754C42.6917 32.4883 47.2969 27.8257 47.2616 22.1091C47.2292 16.9035 43.3357 12.5318 38.1686 11.8994Z"/>
      <path d="M28.894 26.5353C29.4616 27.1231 30.3977 27.1393 30.985 26.5717C31.5724 26.0045 31.5886 25.0685 31.0215 24.4811C31.0096 24.4685 30.9973 24.4566 30.985 24.4447L24.7139 18.1721C24.1373 17.5937 23.2009 17.5926 22.6225 18.1692C22.6218 18.1703 22.6207 18.171 22.62 18.1721L16.3489 24.4447C15.7615 25.0119 15.7453 25.9479 16.3124 26.5353C16.88 27.1227 17.816 27.1389 18.4034 26.5717C18.4157 26.5598 18.4279 26.5476 18.4398 26.5353L22.1884 22.7853V41.3961C22.1884 42.2128 22.8504 42.8749 23.6671 42.8749C24.4835 42.8749 25.1455 42.2128 25.1455 41.3961V22.7853L28.894 26.5353Z" />
    </svg>
);

export default function CustomPhotoDropzone({ onAddBanner, pending, classnames, title }) {
  const { t } = useTranslation();
  const onDrop = useCallback(acceptedFiles => {
    onAddBanner(acceptedFiles[0]);
  }, [onAddBanner]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    disabled: pending,
    onDrop
  });

  return (
    <div {...getRootProps({className: `${styles.container} ${classnames}`})}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <>
            {uploadSvg}
            {
              pending ? <p>
                {t('downloadFile')}
              </p> : <p>
                {title ? t(title) : t('dropPhotos', {count: 8})}
              </p>
            }
          </>
      }
    </div>
  )
}
