import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './questionToopltip.module.scss';
import questionMarkImg from '../../assets/questionMark.svg';

const QuestionTooltip = ({ text = '' }) => {
  const randomID = useRef(String(Math.random()))

  return (
    <>
      <div data-tip={text} data-for={`tooltip-${randomID.current}`} className={styles.container}>
        <img src={questionMarkImg} />
      </div>
      <ReactTooltip
        id={`tooltip-${randomID.current}`}
        className={styles.text}
        place="right"
        type="info"
        data-offset={20}
        backgroundColor="rgba(29, 31, 35, 0.6)"
        arrowColor="rgba(29, 31, 35, 0.6)"
        multiline
      />
    </>
  );
};

export const Tooltip = ({ text = '', children }) => {
  const randomID = useRef(String(Math.random()))

  return (
    <>
      <div data-tip={text} data-for={`tooltip-${randomID.current}`} className={styles.container}>
        {children}
      </div>
      <ReactTooltip
        id={`tooltip-${randomID.current}`}
        className={styles.text}
        place="right"
        type="info"
        data-offset={20}
        backgroundColor="rgba(29, 31, 35, 0.6)"
        arrowColor="rgba(29, 31, 35, 0.6)"
        multiline
      />
    </>
  );
};

export default QuestionTooltip;
